import React from 'react';
import './Main.css';
import Image from '../assets/2.jpg';
import {Row, Col} from 'react-bootstrap';
import { Parallax } from 'react-parallax';


function About() {

  return (
    <div className="content-body">
      <div className="about-hero"></div>
      <Row className="about-text home-gallery">
        <Col xs={12} lg={6}>
            &emsp;&emsp; Making their first small step in 2018, Atelier Wijaya started their humble journey as an Architecture and Interior design firm based in Semarang. Taking projects extending from residential spaces to commercial industries have given the team various precious knowledge on addressing multiple approach to bring clients wishes to life. The team’s core value and design standards are obtained and established by the experience they have gained along the way, making valuable memories by deep research, and understanding the use of each component and space in a room. Without constraining their scope and with a big heart, Atelier Wijaya strives to expand their expertise beyond Indonesia’s horizon.
          <br></br><br></br>&emsp;&emsp; The creation of strong foundations is not only implemented in building, honest and clear communication is the bridge to successful projects in Atelier Wijaya. Constructing an exceptionally exquisite design and aligning it with functionality has been the team’s greatest asset to satisfy clients wishes by incorporating the client in every step of the way. Communication with the clients is greatly respected to the last detail of every material used, likewise the clients are also educated by every decision they make to create a space that is satisfactory for every party involved in the projects.
          <br></br><br></br>&emsp;&emsp; Such quality is applied inside the team as well, helping nurture team members to explore their best qualities without abandoning the main vision as a team inside Atelier Wijaya.
          <br></br><br></br>&emsp;&emsp; As earth materials are naturally formed, the bond and importance of contributing and respecting each other inside Atelier Wijaya are formed as minds and personality collide to create competent work. Individually, all minds in Atelier Wijaya are unique with their own take and perspective in approaching certain designs and material. Nonetheless, as combined, these individuals are able to work together with the vision given by their clients to create a warm live-able and functional spaces. 

        </Col>

        <Col xs={12} lg={6}>
          <div >
            <Parallax bgImage={Image} alt="" strength={100} className="about-img">
              <div className="about-image"></div>
            </Parallax>
          </div>

        </Col>
      </Row>
    </div>
  )
}

export default About