import React from 'react';
import { useParams } from 'react-router-dom';
import { data } from '../assets/Data';
import {AiOutlineLeftCircle} from 'react-icons/ai';
import {Button} from 'react-bootstrap'; 
import {Link} from 'react-router-dom';



function ProjectDetails() {
  let { id } = useParams();

  const detail = data[id-1];
  const increment = n => n+1;




  return (
    <div className="content-body" key={id} >
    <div className="about-hero"></div>
    <div className="home-gallery">
      <Link to ="/projects">
      <Button className="back-icons"><AiOutlineLeftCircle  /></Button>
      </Link>
    <div className="detail-title">{detail.title}</div>
      <img src={detail.img} className="detail-image" alt=""></img>

      {detail.gallery?.map((id, increment) => (
        <img key={id} className="detail-image" src={detail.gallery[increment]} alt="" />
      ))}
      
      </div>
    </div>
  )
}

export default ProjectDetails