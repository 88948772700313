import React from 'react';
import './Main.css';
import {MdEmail} from 'react-icons/md';


function Contacts() {
  return (
    <div className="contact-title content-body">
      <MdEmail className="contact-icon" />wandw.architect@gmail.com
    </div>
  )
}

export default Contacts