import React from 'react';
import './Main.css';
import Logo from '../assets/logo.PNG';
import {CiCircleChevDown} from 'react-icons/ci';
import {motion} from 'framer-motion';
import {data} from '../assets/Data';

import {Link} from 'react-router-dom';
  


function Home() {



  const displayHome = data.map((data, id) => 
  <div key={data.title + id} className="home-image-container">
      <Link to={`/projectdetails/${data.id}`} state={{ data: "data" }} >
    <img src={data.img} alt={`${data.title}`} className="home-image" /> 
    <div className="textbox">{data.title}</div>
    </Link>
  </div>
  )


  const variants = {
    visible: {
      opacity: 1, y: 0, transition: { duration: 1 }
    },
    hidden: {
      opacity: 0, y: 100
    },
  };
  return (

    <div>

    <div className="content-body">

      {/*Home-hero */}
        <div className="home-hero">
              <motion.img initial="hidden" animate="visible" variants={variants} src={Logo} alt="" className="hero-logo" />
              <a id="scroll-btn" href="#section-2"><CiCircleChevDown /></a>
        </div>
      
      {/*Home-Gallery */}

      <div className="home-gallery" id="section-2">
      <div id="grid-2">
      {displayHome}

      </div>
      



      </div>
      </div>
    </div>
  )
}

export default Home