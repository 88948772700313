import React from 'react'
import './Navigation.css';
import { Link } from "react-router-dom";
import DarkMode from '../DarkMode/DarkMode';
import {motion} from "framer-motion";
import Logo from '../../assets/logo.PNG';


function Navigation() {
    const variants = {
        visible: {
            opacity: 1, x: 0, transition: { duration: 1 }
        },
        hidden: {
            opacity: 0, x: 100
        },
    };

  return (
    <div className="sidebar">
          <motion.span initial="hidden" animate="visible" variants={variants}><Link to="/" ><img src={Logo} alt="Atelier Wijaya Logo" className="logo-image" /></Link></motion.span>
          <motion.span initial="hidden" animate="visible" variants={variants}><Link to="/about" className="nav-link"><span className="nav-number">01</span> About</Link></motion.span>
          <motion.span initial="hidden" animate="visible" variants={variants}><Link to="/projects" className="nav-link"><span className="nav-number">02</span> Projects</Link></motion.span>
          <motion.span initial="hidden" animate="visible" variants={variants}><Link to="/contact" className="nav-link"><span className="nav-number">03</span> Contact</Link></motion.span>
          <motion.div initial="hidden" animate="visible" variants={variants} className="toggle-position">
        <DarkMode />
    </motion.div>
    
    </div>
  )
}

export default Navigation