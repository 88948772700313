import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Projects from './pages/Projects';

import Navigation from './components/Navigation/Navigation';
import ProjectDetails from './pages/ProjectDetails';


function App() {
  return (
    <>
      <Router>
      <Navigation/>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contacts />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projectdetails/:id" element={<ProjectDetails />} />
          </Routes>
        </main>
      </Router>

    </>
  );
}

export default App;
