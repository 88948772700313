import {AHouse, Boarding_House, Diamond_Tegal, Candi_Golf, Koffness, AHouse2, Boarding_House2, Candi_Golf2, Citra_Sun, Citra_Sun2, Gold_Retail, IvyPark_BSB, Kedaton_Mansion, Kiddie_Land, Kiddie_Land2, Koffness3, Ningrat, Ningrat2, Ningrat3, Ningrat4, Ningrat5, Nusa_Indah, Nusa_Indah2, Nusa_Indah3, Nusa_Indah4, Nusa_Indah5, Nusa_Indah6, Nusa_Indah7, Nusa_Indah8, Sisinga, Sisinga2, Sisinga3, Sisinga4, Sisinga5, Sisinga6, Sisinga7, Sisinga8, Sweets_Corner, Tigamas, Unreal, WeeNamKee3, WeeNamKee, WeeNamKee2, WeeNamKee4, WeeNamKee5, WeeNamKee6, WeeNamKee7, WeeNamKee8, Koffness2, Koffness4 } from './index';

export const data = [
    {
        id: "1",
        title: "A House",
        category: "Residential",
        img: AHouse,
        gallery: [AHouse2],
    },
    {
        id: "2",
        title: "Boarding House Sampangan",
        category: "Commercial",
        img: Boarding_House,
        gallery: [Boarding_House2],
    },
    {
        id: "3",
        title: "Diamond Retail Tegal",
        category: "Commercial",
        img: Diamond_Tegal,
        gallery: [],

    },
    {
        id: "4",
        title: "House Candi Golf",
        category: "Residential",
        img:  Candi_Golf ,
        gallery: [Candi_Golf2]
    },
    {
        id: "5",
        title: "Citra Sun ",
        category: "Residential",
        img:  Citra_Sun ,
        gallery: [Citra_Sun2],
    },

    {
        id: "6",
        title: "Golden Retail ",
        category: "Commercial",
        img:  Gold_Retail ,
    },

    {
        id: "7",
        title: "Ivy Park BSB ",
        category: "Residential",
        img:  IvyPark_BSB ,
        gallery: [],
    },

    {
        id: "8",
        title: "Kedaton Mansion",
        category: "Residential",
        img:  Kedaton_Mansion ,
        gallery: [],
    },
    {
        id: "9",
        title: "Kiddie Land  ",
        category: "Commercial",
        img: Kiddie_Land  ,
        gallery: [Kiddie_Land2],
    },

    {
        id: "10",
        title: "Koffness  ",
        category: "Commercial",
        img: Koffness  ,
        gallery: [Koffness2, Koffness3, Koffness4],
    },
    {
        id: "11",
        title: "Ningrat  ",
        category: "Residential",
        img: Ningrat  ,
        gallery: [Ningrat2, Ningrat3, Ningrat4, Ningrat5],
    },

    {
        id: "12",
        title: "Nusa Indah  ",
        category: "Residential",
        img: Nusa_Indah  ,
        gallery: [Nusa_Indah2, Nusa_Indah3, Nusa_Indah4, Nusa_Indah5, Nusa_Indah6, Nusa_Indah7, Nusa_Indah8],
    },

    {
        id: "13",
        title: "Sisingamangaraja  ",
        category: "Residential",
        img: Sisinga  ,
        gallery: [Sisinga2, Sisinga3, Sisinga4, Sisinga5, Sisinga6, Sisinga7, Sisinga8],
    },

    {
        id: "14",
        title: "Sweets Corner  ",
        category: "Commercial",
        img:  Sweets_Corner ,
        gallery: [],
    },

    {
        id: "15",
        title: "Tigamas",
        category: "Residential",
        img: Tigamas  ,
        gallery: [],
    },

    {
        id: "16",
        title: " Unreal Projects ",
        category: "Commercial",
        img: Unreal  ,
        gallery: [],
    },

    {
        id: "17",
        title: "Wee Nam Kee ",
        category: "Commercial",
        img: WeeNamKee7   ,
        gallery: [WeeNamKee, WeeNamKee2, WeeNamKee3, WeeNamKee4, WeeNamKee5, WeeNamKee6, WeeNamKee8],
    },


];
