import React, {useState} from 'react';
import {data} from '../assets/Data';
import './Main.css';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';




function Projects() {
  const[datas, setData] = useState(data)

  const displayData = datas.map((data, id) => 
    <div key={data.title + id} className="home-image-container">
      <Link to={`/projectdetails/${data.id}`} state={{ data: "data" }} >
      <img src={data.img} alt={`${data.title}`} className="projects-image" />
      <div className="textbox">{data.title}</div>

      </Link>

    </div>

  )

  const filterResult = (catItem) => {
    const result = data.filter((curData) => {
      return curData.category===catItem;
    });
    setData(result);

  }

  

  return (
    <div className="content-body">
      <div className="about-hero"></div>


      <Row className="home-gallery">
          <Col sm={3} lg={2} className="filter-position">
         <span className="filter-title">Filter By:</span> 
          <Button className="projects-filter" onClick={() => setData(data)}>Show All</Button>
          <Button className="projects-filter" onClick={() =>filterResult('Residential')}>Residential </Button>
          <Button className="projects-filter" onClick={() => filterResult('Commercial')}>Commercial</Button>
          
          </Col>
        <Col sm={9} lg={10}>
          <div id="grid">
          {displayData}

          </div>
          
        </Col>
      </Row>


    </div>
  )
}

export default Projects